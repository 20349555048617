import { Show } from "solid-js";
import { PhosphorIcon } from "~/widgets/icons";

export function Stepper(props: { activeStep: number }) {
  return (
    <div class={`ml-20 mt-4 flex w-auto lg:mx-[15%] lg:mt-8`}>
      <div class="group flex-1 shrink basis-0">
        <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
          <span
            class={`flex size-6 shrink-0 items-center justify-center rounded-full ${props.activeStep === 0 || props.activeStep === 1 ? "bg-[#00AF54]" : "bg-basePrimaryDark text-f12Bold text-white"}`}
          >
            <Show
              when={props.activeStep === 0 || props.activeStep === 1}
              fallback={<>1</>}
            >
              <PhosphorIcon
                name="check"
                fontSize={16}
                size="bold"
                class="text-white"
              />
            </Show>
          </span>

          <div
            class={`h-px w-full flex-1 ${props.activeStep === 1 ? "bg-white" : "bg-basePrimaryDark"} group-last:hidden`}
          ></div>
        </div>
        <div class="-ml-3">
          <span
            class={`text-medium ${props.activeStep === 0 || props.activeStep === 1 ? "text-[#00AF54]" : "text-textNormal"}`}
          >
            Created
          </span>
        </div>
      </div>

      <div class="group flex-1 shrink basis-0">
        <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
          <span
            class={`flex size-6 shrink-0 items-center justify-center rounded-full text-f12Bold ${props.activeStep === 1 ? "bg-white text-textDark" : "bg-basePrimaryDark  text-white"}`}
          >
            2
          </span>
          <div class="h-px w-full flex-1 bg-basePrimaryDark group-last:hidden"></div>
        </div>
        <div class="-ml-6">
          <span
            class={`text-medium ${props.activeStep === 1 ? "text-white" : "text-textNormal"}`}
          >
            Load Money
          </span>
        </div>
      </div>

      <div class="group flex-1 shrink basis-0 lg:flex-none">
        <div class="inline-flex min-h-7 w-full min-w-7 items-center align-middle">
          <span class="flex size-6 shrink-0 items-center justify-center rounded-full bg-basePrimaryDark text-f12Bold text-white ">
            3
          </span>
          <div class="h-px w-full flex-1 bg-basePrimaryDark group-last:hidden"></div>
        </div>
        <div class="-ml-1.5">
          <span class="text-medium text-textNormal">Share</span>
        </div>
      </div>
    </div>
  );
}
